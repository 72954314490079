import React, { useEffect, useState } from "react";
import classes from "./databaseApplication.module.css";
import Sidebar from "../../components/ui/AppShell/Sidebar";
import { Box, Button, Flex, Modal, Pagination, rem, ScrollArea, Select, Skeleton, Text, TextInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { getListApplication } from "../../services/application";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import TableSkeleton from "../../components/ui/TableSkeleton";
import { getListDatabaseApp } from "../../services/databaseApp";
import TableDatabaseApp from "../../components/pages/databaseApp/TablleDatabaseApp";
import FormDatabseApp from "../../components/pages/databaseApp/FormDatabseApp";

const defaultParameter = {
  skip: 0,
  take: 10,
  keywords: "",
  applicationId: "",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Nama Database",
    width: "auto",
  },
  {
    label: "Organisasi",
    width: "auto",
  },
  {
    label: "Aplikasi",
    width: "auto",
  },
  {
    label: "URL Frontend",
    width: "auto",
  },
  {
    label: "URL Backend",
    width: "auto",
  },
  {
    label: "Status",
    width: "auto",
  },
  {
    label: "Tgl Dibuat",
    width: "auto",
  },
];

const DatabaseApplication = () => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [params, setParams] = useState(defaultParameter);
  const [detailData, setDetailData] = useState(null);
  const [databaseAppList, setDatabaseAppList] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedApplicationId, setSelectedApplicationId] = useState("");
  const [applicationList, setApplicationList] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");

  const handleGetListApplication = async () => {
    setLoadingForm(true);
    try {
      const response = await getListApplication();
      const dataApplication = response.data;
      const mappingApplication = [
        { value: "", label: "Semua aplikasi" },
        ...dataApplication.map((val) => ({
          value: val?.id?.toString(),
          label: val?.name.toUpperCase(),
        })),
      ];
      setApplicationList(mappingApplication);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  const handleGetDatabaseApp = async () => {
    setLoading(true);
    try {
      const response = await getListDatabaseApp(params);
      setDatabaseAppList(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetListApplication();
    // eslint-disable-next-line
  }, [selectedApplicationId]);

  useEffect(() => {
    handleGetDatabaseApp();
    // eslint-disable-next-line
  }, [params]);

  const handleSearchChange = (e) => {
    const params = {
      skip: 0,
      take: 10,
      keywords: e.target.value,
      applicationId: "",
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      keywords: "",
      applicationId: "",
    };
    setParams(params);
  };

  const handleChangeApp = (e) => {
    setSelectedApplicationId(e);
    const params = {
      skip: 0,
      take: 10,
      keywords: "",
      applicationId: e,
    };
    setParams(params);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Database Application
        </Text>
        {!loadingForm ? (
          <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <Flex>
              <TextInput mr={8} leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari database. . ." name="search" onChange={handleSearchChange} />
              <Select mr={8} name="applicationId" placeholder="Select aplikasi" searchable nothingFoundMessage="Aplikasi tidak ditemukan" data={applicationList} onChange={handleChangeApp} value={selectedApplicationId} />
            </Flex>
            <Flex mt={isSmallScreen ? 10 : 0}>
              <Button fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#0572b9" onClick={() => setOpenModalForm(true)}>
                Tambah Database App
              </Button>
            </Flex>
          </Box>
        ) : (
          <Box>
            <Skeleton height={40} width="100%" radius="md" mb="md" />
          </Box>
        )}
      </Box>
      <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TableDatabaseApp label={labelTable} data={databaseAppList} countData={params.skip} actionMethod={handleAction} />}</Box>
      <Flex justify="end">
        <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
      </Flex>
      <Modal
        opened={openModalForm}
        onClose={onCloseModalForm}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={<Text fw="Bold">{detailData ? "Edit Database Application" : "Tambah Database Application"}</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={false}
      >
        <FormDatabseApp dataDatabaseApplication={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetDatabaseApp} />
      </Modal>
    </Sidebar>
  );
};

export default DatabaseApplication;
