import React, { useEffect, useState } from "react";
import classes from "./detailFeature.module.css";
import Sidebar from "../../../components/ui/AppShell/Sidebar";
import { Box, Button, Flex, Modal, Pagination, ScrollArea, Text, TextInput, rem } from "@mantine/core";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import TableSkeleton from "../../../components/ui/TableSkeleton";
import TableFeature from "../../../components/pages/feature/TableFeature";
import { getListFeature } from "../../../services/feature";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import FormFeature from "../../../components/pages/feature/FormFeature";

const defaultParameter = {
  orderBy: "name",
  order: "asc",
  skip: 0,
  take: 10,
  keywords: "",
};

const labelTable = [
  {
    label: "No.",
    width: 30,
  },
  {
    label: "Fitur",
    width: "auto",
  },
  {
    label: "App",
    width: "auto",
  },
  {
    label: "status",
    width: "auto",
  },
  {
    label: "Action",
    width: "auto",
  },
];

const DetailFeature = () => {
  const [featureList, setFeatureList] = useState([]);
  const [params, setParams] = useState(defaultParameter);
  const [count, setCount] = useState(0);
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 425px)");
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  const appName = route[route.length - 2];

  const handleGetFeatureList = async () => {
    setLoading(true);
    try {
      const response = await getListFeature({ ...params, applicationId: id });
      setFeatureList(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetFeatureList();
    // eslint-disable-next-line
  }, [params]);

  const handleChangePage = (e) => {
    const params = {
      orderBy: "name",
      order: "asc",
      skip: (e - 1) * 10,
      take: 10,
      applicationId: id,
      keywords: "",
    };
    setParams(params);
  };

  const handleSearchApp = (e) => {
    console.log(e.target.value);
    const params = {
      orderBy: "name",
      order: "asc",
      skip: 0,
      take: 10,
      applicationId: id,
      keywords: e.target.value,
    };
    setParams(params);
  };

  const actions = {
    edit: (val) => {
      setOpenModalForm(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  const onCloseModalForm = () => {
    setOpenModalForm(false);
    setDetailData(null);
  };
  return (
    <Sidebar>
      <Box>
        <Text className={classes.titlePage} mb={20}>
          Fitur Organisasi {appName.toUpperCase()}
        </Text>
      </Box>
      <Box style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
        <Flex>
          <TextInput leftSectionPointerEvents="none" leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} /> placeholder="Cari fitur. . ." name="search" onChange={handleSearchApp} />
        </Flex>
        <Flex mt={isSmallScreen ? 10 : 0}>
          <Button fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#0572b9" onClick={() => setOpenModalForm(true)}>
            Tambah Fitur
          </Button>
        </Flex>
      </Box>
      <Box my={20}>{loading ? <TableSkeleton total={5} /> : <TableFeature label={labelTable} data={featureList} countData={params.skip} actionMethod={handleAction} />}</Box>
      <Flex justify="end">
        <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#0572b9" radius="lg" />
      </Flex>
      <Modal
        opened={openModalForm}
        onClose={onCloseModalForm}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={<Text fw="Bold">{detailData ? "Edit Feature" : "Tambah Feature"}</Text>}
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={false}
      >
        <FormFeature onCloseModal={onCloseModalForm} dataFeature={detailData} reloadList={handleGetFeatureList} />
      </Modal>
    </Sidebar>
  );
};

export default DetailFeature;
