import React, { useEffect } from "react";
import { MantineProvider } from "@mantine/core";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routes, routesAuth, routesAuthSuperAdmin, routesForgotPassword, routesForgotPasswordAccount, routesResetPassword, routesResetPasswordAccount, routesAccountLogin } from "./route";

// css mantine
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { getPermissionRole } from "./services/auth";
import { update } from "./store/authData";
import { updatedRoute } from "./store/permissionRoute";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import http from "./plugins/axios";

function App() {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const routeName = useLocation().pathname.split("/").slice(1);

  const getRoute = (allRoutes) =>
    allRoutes.map((page) => {
      if (!page.hasChildren) {
        return <Route path={page.route} element={page.component} key={page.name} />;
      } else {
        return (
          <Route path={page.route} key={page.name}>
            {childrenRoute(page.children)}
          </Route>
        );
      }
    });

  const childrenRoute = (arrayRoute) =>
    arrayRoute.map((children) => {
      if (children) {
        if (children.route === null) {
          return <Route index element={children.component} key={children.name} />;
        } else {
          return <Route path={children.route} element={children.component} key={children.name} />;
        }
      }
      return null;
    });

  const handleSession = () => {
    const sessionLogin = cookies.get("token");
    const refreshToken = cookies.get("refreshToken");
    const authData = cookies.get("authData_SSO");
    if (sessionLogin && authData) {
      // const decodeData = decodeText(sessionLogin);
      // const dataLogin = JSON.parse(decodeData);
      getHandlePermissionRole();
      http.defaults.headers.common["Authorization"] = `Bearer ${sessionLogin}`;
      dispatch(update({ dataUser: { ...authData, refreshToken, sessionLogin }, isLogin: true }));
      navigate(pathname !== undefined ? pathname : "/dashboard");
    } else if (pathname === "/authentication/sign-in/superadmin") {
      navigate("/authentication/sign-in/superadmin");
    } else if (pathname === "/authentication/sign-in/forgot-password") {
      navigate("/authentication/sign-in/forgot-password");
    } else if (pathname === "/authentication/reset-password/:token") {
      navigate("/authentication/reset-password/:token");
    } else if (routeName[0] === "authentication") {
      navigate(pathname);
    } else {
      navigate("/authentication/sign-in");
    }
  };

  const getHandlePermissionRole = async () => {
    try {
      const response = await getPermissionRole();
      dispatch(updatedRoute({ access: response }));
    } catch (error) {
      console.log(error);
    }
  };

  // initialize session
  useEffect(() => {
    handleSession();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [pathname]);

  return (
    <MantineProvider
      theme={{
        fontFamily: "Goudy Bookletter 1911, sans-serif",
        headings: { fontFamily: "Goudy Bookletter 1911, sans-serif" },
      }}
      withGlobalStyles
    >
      <Notifications autoClose={4000} position="top-right" limit={5} />
      <ModalsProvider>
        <Routes>
          {getRoute(routes)}
          {getRoute(routesAuth)}
          {getRoute(routesAuthSuperAdmin)}
          {getRoute(routesForgotPassword)}
          {getRoute(routesForgotPasswordAccount)}
          {getRoute(routesResetPassword)}
          {getRoute(routesResetPasswordAccount)}
          {getRoute(routesAccountLogin)}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
