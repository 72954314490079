import http from "../plugins/axios";

export const checkAccsessToken = async (payload) => {
  try {
    const response = await http.post(`/access-token/check`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
